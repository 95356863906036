<template>
  <div class="table-orders-container">
    <div class="px-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Размер страницы:</label>
        <b-form-select
            @change="setPageSize"
            :options="optionsPageSize"
        />
      </div>
      <b-button
          v-b-modal.add_smv
          variant="outline-primary"
      >
        <feather-icon
            icon="DownloadIcon"
            class="mr-50"
        />
        <span class="align-middle">Загрузить список SRID СМВ</span>
      </b-button>
    </div>
    <div class="p-2 d-flex flex-wrap justify-content-between align-items-start">
      <div class="mt-1 mr-1 d-flex align-items-center">
        <label class="mr-1">Поиск:</label>
        <b-form-input
            placeholder="Поиск"
            type="text"
            class="d-inline-block"
            @input="setFindText"
        />
      </div>
      <div class="mt-1 mr-1 d-flex align-items-center" style="height: 37px">
        <b-form-checkbox
            @change="setSelfRansomFilter"
            :checked="getSelfRansomFilter"
        >
          СМВ
        </b-form-checkbox>
      </div>
      <div class="data-interval d-flex">
        <div class="date-from mt-1 mr-1 d-flex align-items-center">
          <b-form-datepicker
              id="date-from"
              v-model="dateFrom"
              :label-no-date-selected="'Дата от'"
              :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
          />
        </div>
        <div class="date-to mt-1 mr-1 d-flex align-items-center">
          <b-form-datepicker
              id="date-to"
              v-model="dateTo"
              :label-no-date-selected="'Дата до'"
              :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric'}"
          />
        </div>
      </div>
      <div class="d-flex flex-wrap">
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Статус:</label>
          <b-form-select
              @change="setSelectedState"
              :options="filterState"
          />
        </div>
        <div class="mt-1 mr-1 d-flex align-items-center">
          <label class="mr-1">Бренд:</label>
          <b-form-select
              @change="setSelectedBrand"
              :options="filterBrand"
          />
        </div>
        <div class="d-flex mt-1 align-items-center">
          <label class="mr-1">Категория:</label>
          <b-form-select
              @change="setSelectedObject"
              :options="filterObject"
          />
        </div>
      </div>
    </div>
    <b-table
        id="table_load_products"
        responsive
        :fields="fields"
        first-number
        last-number
        show-empty
        thead-tr-class="text-nowrap"
        :details-td-class="'p-0'"
        :items="getOrders"
        :current-page="currentPage"
        :per-page="pageSize">
      <template #empty="scope">
        <h4 v-if="loadOrdersState" class="load-orders">
          Список заказов пуст
        </h4>
        <h4 v-else class="load-orders">
          Список заказов загружается ...
          <b-spinner style="width: 30px; height: 30px;" :variant="'secondary'"></b-spinner>
        </h4>
      </template>
      <template #head(selectAll)="data">
        <div style="width: 20px">
          <b-form-checkbox
              @change="function(val) {selectAllSridOrder(val)}"
              v-bind:checked="getCurrState"
          />
        </div>
      </template>
      <template #cell(status)="data">
        <order-status
            :order-status="data.item.status"
            :self-ransom="data.item.selfRansom"
            :date="data.item.date"
        />
      </template>
      <template #cell(name)="data">
        <product-cell-simple
            :url="data.item.url"
            :name="data.item.object"
            :vendorCode="data.item.vendorCode"
            :brand="data.item.brand"
        />
      </template>
      <template #cell(regionName)="data">
        <div>
          {{ data.item.region_name }}
        </div>
        <div>
          {{ data.item.oblast_okrug_name }}
        </div>
      </template>
      <template #cell(finishedPrice)="data">
        <div class="font-weight-bold"
             v-if="data.item.finished_price"
        >{{ String(Number(data.item.finished_price).toFixed()).replace(/(\d{1,3})(?=((\d{3})*([^\d]|$)))/g, " $1 ") }}
        </div>
        <div class="text-nowrap" style="font-size: 13px"
             v-if="data.item.totalprice"
        >
          <span style="color: #85858a">{{
              String(Number(data.item.totalprice).toFixed()).replace(/(\d{1,3})(?=((\d{3})*([^\d]|$)))/g, " $1 ")
            }}</span>
          <span style="color: #d87a68">{{ ` - ${data.item.discountpercent}%` }}</span>
        </div>
      </template>
      <template #cell(spp)="data">
        <div v-if="data.item.spp" style="color: #d87a68">{{ ` - ${data.item.spp}%` }}</div>
      </template>
      <template #cell(selectAll)="data">
        <b-form-checkbox
            v-bind:checked="selectedOrders.includes(data.item.srid) || selectedOrdersRm.includes(data.item.srid)"
            @change="function(val) {selectSridOrder({val, srid: data.item.srid, smv: data.item.selfRansom})}"
        />
      </template>
    </b-table>
    <div class="block-pagination my-1">
      <b-row>
        <b-col
            cols="12">
          <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="pageSize"
              first-number
              last-number
              align="center"
              prev-class="prev-item"
              next-class="next-item">
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal class="load-products-from-mp"
             id="add_smv"
             hide-header
             hide-footer
             centered
             v-model="showSMVModal"
    >
      <pop-up-s-m-v
          @close="showSMVModal = false"
      />
    </b-modal>
  </div>
</template>

<script>

import {
  BButton,
  BCard,
  BTable,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BSpinner,
  BModal,
  BFormInput,
  BFormGroup,
  BPagination,
  BFormSelect,
  BFormCheckbox,
  BFormDatepicker,
  BRow,
  VBModal,
} from 'bootstrap-vue';
import OrderStatus from './OrderStatus';
import ProductCellSimple from '../ProductCellSimple';
import PopUpSMV from './PopUpSMV';
import {mapActions, mapGetters, mapState} from "vuex";

export default {
  name: "TableOrders",
  data() {
    return {
      dateFrom: '',
      dateTo: '',
      currentPage: 1,
      showSMVModal: false,
      fields: [
        {
          key: 'status',
          label: 'Статус',
        },
        {
          key: 'name',
          label: 'Товар',
        },
        {
          key: 'techsize',
          label: 'Размер',
        },
        {
          key: 'incomeid',
          label: '№ Поставки',
        },
        {
          key: 'gnumber',
          label: '№ продажи',
        },
        {
          key: 'warehousename',
          label: 'Склад',
        },
        {
          key: 'regionName',
          label: 'Регион',
        },
        {
          key: 'finishedPrice',
          label: 'Цена',
        },
        {
          key: 'spp',
          label: 'Скидка WB',
        },
        {
          key: 'selectAll',
        },
      ],
      objectFilter: '',
      currentDetailID: null,
      show: false
    }
  },

  methods: {
    ...mapActions('orders', ["setSelfRansomFilter", "selectSridOrder", 'loadOrders', "setPageSize", 'selectAllSridOrder', "setFindText", "setSelectedBrand", "setSelectedObject", "setSelectedState"]),
    ...mapActions('products', ['showProductPanel', "hideProductPanel"]),
  },

  computed: {
    getSelfRansomFilter() {
      return this.selfRansomFilter === 1;
    },

    getCurrState() {
      return this.selectedOrders.length > 0 || this.selectedOrdersRm.length > 0;
    },
    rows() {
      return this.getOrders.length
    },
    ...mapState('orders', ["pageSize", "selfRansomFilter", "selectedOrdersRm", "selectedOrdersRm", "loadOrdersState", "optionsPageSize", "selectedOrders", "findText", "filterBrand", "filterObject", "selectedObject", "selectedBrand", "filterState", "selectedState"]),
    ...mapGetters('orders', ['getOrders'])
  },

  watch: {
    async dateTo(date) {
      if (this.dateFrom && this.dateTo) {
        await this.loadOrders({dateFrom: this.dateFrom, dateTo: this.dateTo});
      }
    },
    async dateFrom(date) {
      if (this.dateFrom && this.dateTo) {
        await this.loadOrders({dateFrom: this.dateFrom, dateTo: this.dateTo});
      }
    }
  },

  components: {
    ProductCellSimple,
    BCard,
    BTabs,
    BTab,
    BCardText,
    BSpinner,
    BButton,
    BPagination,
    BFormDatepicker,
    BCol,
    BModal,
    BTable,
    BRow,
    BFormInput,
    BFormCheckbox,
    BFormGroup,
    BFormSelect,
    OrderStatus,
    PopUpSMV
  },
  async mounted() {
    await this.loadOrders();
    this.showProductPanel()
  },

  async unmounted() {
    this.hideProductPanel()
  }
}
</script>

<style scoped>

.load-orders {
  font-size: 28px;
  margin: 10px;
  color: #c0bcbc;
  text-align: center;
}
</style>